/* eslint-disable max-lines-per-function */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable max-lines */
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { driver } from 'driver.js';
import { useContext, useEffect, useState } from 'react';

import {
  EvaluationType,
  PropertyType,
  StepTour,
  convertClientKind,
  convertEvaluationType,
  convertPropertyType,
} from '../../api/enumerations';
import {
  CustomPatternFormat,
  CustomTextField,
  NumericTextField,
  SelectTextField,
} from '../../components/CustomInput';
import { AttachFilesDialog } from '../../components/Dialog/AttachFilesDialog';
import ConfirmationDialog from '../../components/Dialog/ConfirmationDialog';
import { MessagesDialog } from '../../components/Dialog/Messages';
import { PeptDialog } from '../../components/Dialog/PeptDialog';
import StandardMap from '../../components/Maps/StandardMap';
import { AccordionTitle } from '../../components/Sections/AccordionTitle';
import { ClientData } from '../../components/Sections/ClientData';
import { EditWorkOrder } from '../../components/Sections/EditWorkOrder';
import { Observations } from '../../components/Sections/Observations';
import { WorkOrderProgressBar } from '../../components/Sections/ProgressBar';
import { PropertyAddressWithMaps } from '../../components/Sections/PropertyAddressWithMaps';
import { PropertyData } from '../../components/Sections/PropertyData';
import { PropertyInfo } from '../../components/Sections/PropertyInfo';
import { Rooms } from '../../components/Sections/Rooms';
import { ScheduleInfo } from '../../components/Sections/ScheduleInfo';
import { Title } from '../../components/Sections/Title';
import { WorkOrderFile } from '../../components/Sections/WorkOrderFile';
import { ToogleAccordion } from '../../components/ToogleAccordion';
import {
  BoxContainer,
  ButtonsSpace,
  CancelOsBox,
  FlexSpaceBetweenBox,
  LoadingBox,
  SectionBox,
  SubmitBox,
} from '../../components/UI/Box';
import {
  BackButton,
  CancelOSButton,
  FilledButton,
  RoundedButton,
} from '../../components/UI/Button';
import { GridContainer } from '../../components/UI/Grid';
import {
  InputTitle,
  SectionTitle,
  StyledInput,
} from '../../components/UI/Typography';
import {
  IconApartmentMS,
  IconArrowCircleLeftMS,
  IconCalendarTodayMS,
  IconCancelMS,
  IconListAltMS,
  IconLocationCityMS,
  IconLocationOnMS,
  IconPersonMS,
  IconPhotoLibraryMS,
} from '../../constants/icons';
import { Constants } from '../../constants/inspection';
import {
  selectConcept,
  selectConservation,
  selectPropertyUse,
  selectRegistrationUf,
  selectReportFunction,
  selectReportGoal,
  selectStandard,
} from '../../constants/selectOptions';
import { GlobalContext } from '../../context/global';
import { driverConfig } from '../../helpers/driver/config';
import { inspectionSteps } from '../../helpers/driver/steps';
import { useAccordion } from '../../hooks/useAccordion';
import { useCancelWorkOrder } from '../../hooks/useCancelWorkOrder';
import { useChangeStatus } from '../../hooks/useChangeStatus';
import useGeneral from '../../hooks/useGeneral';
import { useTour } from '../../hooks/useTour';
import { FacadeModal } from './FacadeModal';
import { InspectionForm } from './Form';
import useInspection from './hooks';
import { BoldTypography, CoordGrid, FacadeImage, FlexBox } from './styles';

export default function Inspection(): JSX.Element {
  const isBank = process.env.REACT_APP_IS_BANK === 'true';

  const { navigateHome, osId } = useGeneral();
  const { handlePreviousStatus, loadingPreviousStatus } = useChangeStatus();
  const { handleCancelWorkOrder } = useCancelWorkOrder();
  const {
    expandOne,
    setExpandOne,
    expandTwo,
    setExpandTwo,
    expandThree,
    setExpandThree,
    expandFour,
    setExpandFour,
    expandFive,
    setExpandFive,
    expandSix,
    setExpandSix,
    expandAll,
    setExpandAll,
    toogleAccordions,
  } = useAccordion();
  const {
    handleProgress,
    handleSubmit,
    verifyCep,
    handleFileUpload,
    address,
    setAddress,
    addressNumber,
    setAddressNumber,
    age,
    setAge,
    buildingStandard,
    setBuildingStandard,
    builtArea,
    setBuiltArea,
    cep,
    setCep,
    city,
    setCity,
    client,
    setClient,
    complement,
    setComplement,
    concept,
    setConcept,
    conservation,
    setConservation,
    district,
    setDistrict,
    goal,
    setGoal,
    judicialDistrict,
    setJudicialDistrict,
    parkingLots,
    setParkingLots,
    propertyFunction,
    setPropertyFunction,
    propertyUse,
    setPropertyUse,
    registerNumber,
    setRegisterNumber,
    registrationUf,
    setRegistrationUf,
    propertyRooms,
    setPropertyRooms,
    rooms,
    solicitor,
    setSolicitor,
    suites,
    setSuites,
    toilets,
    setToilets,
    totalArea,
    setTotalArea,
    uf,
    setUf,
    zone,
    setZone,
    propertyData,
    registerFileName,
    iptuFileName,
    searchMap,
    setSearchMap,
    setIsAddressEdited,
    pinPlace,
    setPinPlace,
    questionForm,
    progressValue,
    inspectionId,
    enableEditing,
    setEnableEditing,
    downloadIptu,
    downloadRegister,
    submitLoading,
    handleCheckLocation,
    checkLocationDate,
    handleApprove,
    loadingApprove,
    loadingPage,
    getDataCallback,
    handleUpdateForm,
    completedSections,
  } = useInspection();
  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const {
    isTourOn,
    setTourOn,
    tourCompletion,
    setTourCompletion,
    toBeContinued,
    setToBeContinued,
    setTutorialStep,
    tourSelection,
    setTourSelection,
    driveIsActive,
    setDriveIsActive,
  } = useTour();

  const driverObj = driver({
    ...driverConfig,
    steps: inspectionSteps,
    onNextClick: () => {
      if (driverObj.isLastStep()) {
        setTourCompletion((prev) => {
          const newTourCompletion = { ...prev };
          const inspectionIndex = newTourCompletion.inspection.findIndex(
            (item) => item.stage === 'inspection'
          );
          if (inspectionIndex !== -1) {
            const updatedInspectionIndex = {
              ...newTourCompletion.inspection[inspectionIndex],
            };
            updatedInspectionIndex.complete = true;
            updatedInspectionIndex.step = inspectionSteps.length;
            newTourCompletion.inspection[inspectionIndex] =
              updatedInspectionIndex;
          }
          return newTourCompletion;
        });
        setToBeContinued(false);
        setTourSelection(false);
        setDriveIsActive(false);
      }
      driverObj.moveNext();
    },
    onCloseClick: () => {
      setTourSelection(false);
      setToBeContinued(false);
      setDriveIsActive(false);
      driverObj.destroy();
    },
    onDestroyStarted: () => {
      setTourSelection(false);
      setToBeContinued(false);
      setDriveIsActive(false);
      driverObj.destroy();
    },
  });

  const [tourHasStarted, setTourHasStarted] = useState(false);

  useEffect(() => {
    if (
      !tourCompletion.inspection[0].complete &&
      !tourCompletion.skipTour.inspection
    ) {
      setTutorialStep(StepTour.INSPECTIONSTART);
      if (!driveIsActive && !tourHasStarted && !loadingPage) {
        setTourOn(true);
        setTourHasStarted(true);
      } else if (!isTourOn && driveIsActive && !loadingPage) {
        setExpandTwo(true);
        setExpandFive(true);
        setExpandSix(true);

        setTimeout(() => {
          driverObj.drive();
        }, 500);
      }
    } else if (tourSelection && driveIsActive && !isTourOn) {
      setTutorialStep(StepTour.INSPECTIONSTART);
      setExpandTwo(true);
      setExpandFive(true);
      setExpandSix(true);
      setTimeout(() => {
        driverObj.drive();
      }, 500);
    }
  }, [
    tourCompletion,
    toBeContinued,
    isTourOn,
    driveIsActive,
    loadingPage,
    tourHasStarted,
  ]);

  useEffect(() => {
    if (
      expandOne &&
      expandTwo &&
      expandThree &&
      expandFour &&
      expandFive &&
      expandSix
    ) {
      setExpandAll(true);
    } else {
      setExpandAll(false);
    }
  }, [expandOne, expandTwo, expandThree, expandFour, expandFive, expandSix]);

  const showSchedulingAccordion =
    propertyData?.evaluation_type === EvaluationType.SIMPFACTORS ||
    propertyData?.evaluation_type === EvaluationType.SIMPINFERENCES;

  return (
    <GridContainer>
      <BackButton onClick={navigateHome}>{IconArrowCircleLeftMS}</BackButton>
      <BoxContainer component="form" id="inspection" onSubmit={handleSubmit}>
        <Title
          osNumber={propertyData?.reference_number || 0}
          title={Constants.inspection}
          createdAt={propertyData?.created_at}
        />
        {loadingPage ? (
          <LoadingBox>
            <CircularProgress />
          </LoadingBox>
        ) : (
          <>
            <FlexSpaceBetweenBox>
              <FlexBox>
                {!isBank && (
                  <CancelOsBox>
                    <ConfirmationDialog
                      text={Constants.cancelOsText}
                      button={
                        <CancelOSButton>
                          {IconCancelMS}
                          {Constants.cancelOs}
                        </CancelOSButton>
                      }
                      model="error"
                      modalCallback={handleCancelWorkOrder}
                    />
                  </CancelOsBox>
                )}
                <EditWorkOrder
                  edit={enableEditing}
                  setEdit={setEnableEditing}
                />
              </FlexBox>
              <FlexSpaceBetweenBox gap="10px">
                {isBank && propertyData?.worker_company_id !== null && (
                  <MessagesDialog />
                )}
                <AttachFilesDialog propertyData={propertyData} osId={osId} />
              </FlexSpaceBetweenBox>
            </FlexSpaceBetweenBox>
            <WorkOrderProgressBar complete={completedSections.clientData}>
              <>
                {enableEditing ? (
                  <SectionBox>
                    <SectionTitle mb="20px">
                      {IconPersonMS}
                      {Constants.clientData}
                    </SectionTitle>
                    <Grid container spacing={4}>
                      <Grid item xs={6} xl={4}>
                        <CustomTextField
                          required
                          id="client"
                          label="proprietário do imóvel"
                          value={client}
                          setValue={setClient}
                        />
                      </Grid>
                      <Grid item xs={6} xl={4}>
                        <CustomTextField
                          id="solicitor"
                          label="solicitante"
                          value={solicitor}
                          setValue={setSolicitor}
                        />
                      </Grid>
                      <Grid item xs={6} xl={4}>
                        <SelectTextField
                          id="os-goal"
                          label="objetivo"
                          value={goal}
                          setValue={setGoal}
                          selectOptions={selectReportGoal()}
                        />
                      </Grid>
                      <Grid item xs={6} xl={3}>
                        <InputTitle>{Constants.evaluationType}</InputTitle>
                        <StyledInput>
                          {propertyData &&
                            convertEvaluationType(propertyData.evaluation_type)}
                        </StyledInput>
                      </Grid>
                      <Grid item xs={4} xl={3}>
                        <InputTitle>{Constants.clientType}</InputTitle>
                        <StyledInput>
                          {propertyData &&
                            convertClientKind(propertyData.client_kind)}
                        </StyledInput>
                      </Grid>
                      <Grid item xs={4} xl={3}>
                        <SelectTextField
                          id="os-function"
                          label="finalidade"
                          value={propertyFunction}
                          setValue={setPropertyFunction}
                          selectOptions={selectReportFunction()}
                        />
                      </Grid>
                      <Grid item xs={4} xl={3}>
                        <SelectTextField
                          id="property-use"
                          label="uso do imóvel"
                          value={propertyUse}
                          setValue={setPropertyUse}
                          selectOptions={selectPropertyUse()}
                        />
                      </Grid>
                    </Grid>
                  </SectionBox>
                ) : (
                  <ClientData propertyData={propertyData} />
                )}
                <ToogleAccordion expand={expandAll} toogle={toogleAccordions} />
              </>
            </WorkOrderProgressBar>
            <WorkOrderProgressBar complete={completedSections.propertyData}>
              <SectionBox>
                <AccordionTitle
                  title={Constants.propertyData}
                  icon={IconApartmentMS}
                  openAccordion={expandOne}
                  setOpenAccordion={setExpandOne}
                />
                {expandOne &&
                  (enableEditing ? (
                    <>
                      <WorkOrderFile
                        downloadRegister={downloadRegister}
                        downloadIptu={downloadIptu}
                        registerFileName={registerFileName}
                        iptuFileName={iptuFileName}
                        handleFileUpload={handleFileUpload}
                      />
                      <Grid container spacing={4}>
                        <Grid item xs={4}>
                          <CustomTextField
                            required
                            id="register-number"
                            label="nº da matrícula"
                            value={registerNumber}
                            setValue={setRegisterNumber}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <InputTitle>tipo do imóvel:</InputTitle>
                          <StyledInput>
                            {propertyData &&
                              convertPropertyType(
                                propertyData.real_estate_kind
                              )}
                          </StyledInput>
                        </Grid>
                        <Grid item xs={4}>
                          <SelectTextField
                            id="concept"
                            label="conceito do espaço"
                            value={concept}
                            setValue={setConcept}
                            selectOptions={selectConcept()}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <NumericTextField
                            id="zone"
                            label="ofício/zona"
                            suffix=""
                            maxLength={12}
                            value={zone}
                            setValue={setZone}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <CustomTextField
                            required
                            id="judicial-district"
                            label="comarca"
                            value={judicialDistrict}
                            setValue={setJudicialDistrict}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <SelectTextField
                            id="registration-uf"
                            label="UF"
                            value={registrationUf}
                            setValue={setRegistrationUf}
                            selectOptions={selectRegistrationUf()}
                          />
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    <PropertyData propertyData={propertyData} />
                  ))}
              </SectionBox>
            </WorkOrderProgressBar>
            <WorkOrderProgressBar complete={completedSections.propertyAddress}>
              <SectionBox>
                <AccordionTitle
                  title={Constants.propertyAddress}
                  icon={IconLocationOnMS}
                  openAccordion={expandTwo}
                  setOpenAccordion={setExpandTwo}
                />
                {expandTwo &&
                  (enableEditing ? (
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Grid container spacing={2}>
                          <Grid item xs={4}>
                            <CustomPatternFormat
                              required
                              id="cep"
                              label="CEP"
                              value={cep}
                              setValue={setCep}
                              format="#####-###"
                              helper={verifyCep}
                            />
                          </Grid>
                          <Grid item xs={8}>
                            <CustomTextField
                              required
                              id="address"
                              label="logradouro"
                              value={address}
                              setValue={setAddress}
                              onChange={setIsAddressEdited}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <CustomTextField
                              required
                              id="address-number"
                              label="número"
                              value={addressNumber}
                              setValue={setAddressNumber}
                            />
                          </Grid>
                          <Grid item xs={8}>
                            <CustomTextField
                              id="complement"
                              label="complemento"
                              value={complement}
                              setValue={setComplement}
                              onChange={setIsAddressEdited}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <CustomTextField
                              required
                              id="district"
                              label="bairro"
                              value={district}
                              setValue={setDistrict}
                              onChange={setIsAddressEdited}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <CustomTextField
                              required
                              id="city"
                              label="cidade"
                              value={city}
                              setValue={setCity}
                              onChange={setIsAddressEdited}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <CustomTextField
                              required
                              id="uf"
                              label="estado"
                              value={uf}
                              setValue={setUf}
                              onChange={setIsAddressEdited}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <RoundedButton
                              onClick={() => {
                                setSearchMap(!searchMap);
                                setIsAddressEdited(false);
                              }}
                            >
                              {Constants.searchMap}
                            </RoundedButton>
                          </Grid>
                          <CoordGrid item xs={6}>
                            <BoldTypography>{Constants.lat}</BoldTypography>
                            <Typography>{pinPlace.lat}</Typography>
                          </CoordGrid>
                          <CoordGrid item xs={6}>
                            <BoldTypography>{Constants.lng}</BoldTypography>
                            <Typography>{pinPlace.lng}</Typography>
                          </CoordGrid>
                        </Grid>
                      </Grid>
                      <Grid item xs={6}>
                        <StandardMap
                          searchMap={searchMap}
                          address={`${address} ${addressNumber} ${district} ${city}`}
                          pinPlace={pinPlace}
                          setPinPlace={setPinPlace}
                        />
                      </Grid>
                    </Grid>
                  ) : (
                    <PropertyAddressWithMaps
                      checkLocation
                      propertyData={propertyData}
                      handleCheckLocation={handleCheckLocation}
                      checkLocationDate={checkLocationDate && checkLocationDate}
                    />
                  ))}
              </SectionBox>
            </WorkOrderProgressBar>
            <WorkOrderProgressBar
              complete={completedSections.propertyCharacteristic}
            >
              <SectionBox>
                <AccordionTitle
                  title={Constants.propertyDetails}
                  icon={IconLocationCityMS}
                  openAccordion={expandThree}
                  setOpenAccordion={setExpandThree}
                />
                {expandThree &&
                  (enableEditing ? (
                    <Grid container spacing={4}>
                      <Grid item xs={2}>
                        <NumericTextField
                          id="total-area"
                          label={
                            propertyData?.real_estate_kind ===
                            PropertyType.APARTMENT
                              ? 'área total'
                              : 'área do terreno'
                          }
                          suffix=" m²"
                          decimalSeparator=","
                          decimalScale={2}
                          maxLength={18}
                          value={totalArea}
                          setValue={setTotalArea}
                        />
                      </Grid>
                      {propertyData?.real_estate_kind !== PropertyType.LOT && (
                        <>
                          <Grid item xs={2}>
                            <NumericTextField
                              id="built-area"
                              label={
                                propertyData?.real_estate_kind ===
                                PropertyType.APARTMENT
                                  ? 'área privativa'
                                  : 'área construída'
                              }
                              suffix=" m²"
                              decimalSeparator=","
                              decimalScale={2}
                              maxLength={18}
                              value={builtArea}
                              setValue={setBuiltArea}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <NumericTextField
                              id="rooms"
                              label="quartos"
                              suffix=" quartos"
                              maxLength={12}
                              value={propertyRooms}
                              setValue={setPropertyRooms}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <NumericTextField
                              id="toilets"
                              label="banheiros"
                              suffix=" banheiros"
                              maxLength={13}
                              value={toilets}
                              setValue={setToilets}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <NumericTextField
                              id="suites"
                              label="suítes"
                              suffix=" suítes"
                              maxLength={10}
                              value={suites}
                              setValue={setSuites}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <NumericTextField
                              id="parking-lots"
                              label="vagas de garagem"
                              suffix=" vagas"
                              maxLength={9}
                              value={parkingLots}
                              setValue={setParkingLots}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <NumericTextField
                              id="propertyAge"
                              label="idade do imóvel"
                              suffix=" anos"
                              maxLength={9}
                              value={age}
                              setValue={setAge}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <SelectTextField
                              id="conservation"
                              label="estado de conservação do imóvel"
                              value={conservation}
                              setValue={setConservation}
                              selectOptions={selectConservation()}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <SelectTextField
                              id="building-standard"
                              label="padrão construtivo"
                              value={buildingStandard}
                              setValue={setBuildingStandard}
                              selectOptions={selectStandard()}
                            />
                          </Grid>
                        </>
                      )}
                    </Grid>
                  ) : (
                    <PropertyInfo propertyData={propertyData} />
                  ))}
              </SectionBox>
            </WorkOrderProgressBar>
            {showSchedulingAccordion && (
              <WorkOrderProgressBar complete>
                <SectionBox>
                  <AccordionTitle
                    title={Constants.scheduleInfo}
                    icon={IconCalendarTodayMS}
                    openAccordion={expandFour}
                    setOpenAccordion={setExpandFour}
                  />
                  {expandFour && (
                    <ScheduleInfo
                      inspectionData={propertyData.inspection}
                      rooms={rooms}
                    />
                  )}
                </SectionBox>
              </WorkOrderProgressBar>
            )}
            <WorkOrderProgressBar complete={completedSections.propertyImages}>
              <SectionBox id="rooms-container">
                <AccordionTitle
                  title={Constants.propertyPhotos}
                  icon={IconPhotoLibraryMS}
                  openAccordion={expandFive}
                  setOpenAccordion={setExpandFive}
                />
                {expandFive && (
                  <Box>
                    {propertyData && (
                      <Box>
                        {propertyData.inspection?.engineer_id !== null ? (
                          <Rooms
                            navigationPath={`${osId}/inspection/${inspectionId}/rooms`}
                            rooms={rooms}
                            osId={osId}
                            inspectionStatus
                            inspectionId={propertyData.inspection?.id}
                            showUpload
                            responsiblePicture={
                              propertyData.inspection
                                ?.responsible_picture_filename
                            }
                            updatePropertyData={getDataCallback}
                          />
                        ) : propertyData.facade_image ? (
                          <FacadeImage image={propertyData?.facade_image} />
                        ) : propertyData.evaluation_type ===
                            EvaluationType.AUTOFACTORS ||
                          propertyData.evaluation_type ===
                            EvaluationType.AUTOINFERENCES ? (
                          <FacadeModal
                            osId={osId}
                            updatePropertyData={getDataCallback}
                          />
                        ) : (
                          <Box>{Constants.noPhotos}</Box>
                        )}
                      </Box>
                    )}
                  </Box>
                )}
              </SectionBox>
            </WorkOrderProgressBar>
            <WorkOrderProgressBar
              complete={completedSections.form}
              lastAccordion
            >
              <SectionBox id="form-container">
                <AccordionTitle
                  title={Constants.form}
                  icon={IconListAltMS}
                  openAccordion={expandSix}
                  setOpenAccordion={setExpandSix}
                />
                {expandSix && (
                  <Box>
                    {propertyData?.inspection?.form_response && questionForm ? (
                      <InspectionForm
                        questionForm={questionForm}
                        engineerId={propertyData.inspection.engineer_id}
                        inspectionId={propertyData.inspection.id}
                        progress={progressValue}
                        handleProgress={handleProgress}
                        handleUpdateForm={handleUpdateForm}
                      />
                    ) : (
                      <Typography>{Constants.noForm}</Typography>
                    )}
                  </Box>
                )}
              </SectionBox>
            </WorkOrderProgressBar>
            <SubmitBox>
              <PeptDialog
                osId={osId}
                referenceNumber={propertyData?.reference_number}
              />
              <ButtonsSpace>
                <ConfirmationDialog
                  text={Constants.previousStatusText.replace(
                    '**',
                    `${propertyData?.reference_number}`
                  )}
                  errorMessage={Constants.previousStatusWarning}
                  button={
                    <FilledButton
                      width="md"
                      model="warning"
                      disabled={loadingPreviousStatus}
                    >
                      {loadingPreviousStatus ? (
                        <CircularProgress size={22} />
                      ) : (
                        Constants.previousStatus
                      )}
                    </FilledButton>
                  }
                  modalCallback={() =>
                    handlePreviousStatus(
                      osId,
                      propertyData?.reference_number,
                      Constants.previousStatusName
                    )
                  }
                />
                {enableEditing ? (
                  <FilledButton
                    id="approve-btn"
                    form="inspection"
                    type="submit"
                    disabled={submitLoading}
                  >
                    {submitLoading ? (
                      <CircularProgress size={22} />
                    ) : (
                      Constants.confirmEdit
                    )}
                  </FilledButton>
                ) : checkLocationDate ? (
                  <ConfirmationDialog
                    id="approve-btn"
                    loading={loadingApprove}
                    title={Constants.approve}
                    text={Constants.changeStatus.replace(
                      '**',
                      `${propertyData?.reference_number}`
                    )}
                    modalCallback={handleApprove}
                  />
                ) : (
                  <FilledButton
                    id="approve-btn"
                    onClick={() => {
                      setSnackbarMessage('Localização pendente de confirmação');
                      setOpenSnackbar(true);
                      setErrorMessage(true);
                    }}
                  >
                    {Constants.approve}
                  </FilledButton>
                )}
              </ButtonsSpace>
            </SubmitBox>
            <Observations />
          </>
        )}
      </BoxContainer>
    </GridContainer>
  );
}
