/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react';

import {
  inspectionAPI,
  pictureAPI,
  sampleAPI,
  workOrderAPI,
} from '../../../api';
import { EvaluationType, Status, StatusCode } from '../../../api/enumerations';
import { GalleryPictureData } from '../../../api/pictures/types';
import { RoomData } from '../../../api/rooms/types';
import { ElementData } from '../../../api/sample/types';
import {
  AvmFactorsData,
  AvmInferenceData,
  WorkOrderData,
} from '../../../api/workOrders/types';
import { useChangeStatus } from '../../../hooks/useChangeStatus';
import useErrorMessage from '../../../hooks/useErrorMessage';
import useGeneral from '../../../hooks/useGeneral';
import { usePropertyData } from '../../../hooks/usePropertyData';
import { useRooms } from '../../../hooks/useRooms';
import useSnackbar from '../../../hooks/useSnackbar';

type LatLngLiteral = google.maps.LatLngLiteral;

interface SendGraphProps {
  title: string;
  subtitle?: string[] | [];
}

interface ICompleteSections {
  additionalFields: boolean;
  inspectionImages: boolean;
}

interface ReportHook {
  propertyData: WorkOrderData | undefined;
  sampleData: ElementData[] | undefined;
  hasAvmReport: boolean;
  rooms: RoomData[];
  selectedPictures: GalleryPictureData[];
  getCharts: (img: HTMLCanvasElement, title: string) => Promise<void>;
  handleChangeStatus: () => Promise<void>;
  removeSelectedPicture: (id: number) => Promise<void>;
  forceFactors: string[];
  forceTransformations: string[];
  loadingApprove: boolean;
  loadingPage: boolean;
  loadingSample: boolean;
  avmStatus: string;
  completedSections: ICompleteSections;
  updateAdditionalFields: () => void;
  getDataCallback: () => Promise<void>;
}

const useReport = (): ReportHook => {
  const [hasAvmReport, setHasAvmReport] = useState(false);
  const [sampleData, setSampleData] = useState<ElementData[]>();
  const [sampleRadius, setSampleRadius] = useState<number>();
  const [avmStatus, setAvmStatus] = useState('');
  const [forceFactors, setForceFactors] = useState<string[]>([]);
  const [forceTransformations, setForceTransformations] = useState<string[]>(
    []
  );
  const [pinPlace, setPinPlace] = useState<LatLngLiteral>();
  const [hasGraphs, setHasGraphs] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const [graphsLength, setGraphsLength] = useState<number>(1);
  const [metadata, setMetadata] = useState<SendGraphProps[]>([]);
  const [selectedPictures, setSelectedPictures] = useState<
    GalleryPictureData[]
  >([]);
  const [loadingPage, setLoadingPage] = useState(true);
  const [loadingSample, setLoadingSample] = useState(false);
  const [updatedAdditionaFields, setUpdatedAdditionalFields] = useState(false);
  const [completedSections, setCompletedSections] = useState<ICompleteSections>(
    {
      additionalFields: false,
      inspectionImages: false,
    }
  );

  const { handleStatus, loadingApprove, setLoadingApprove } = useChangeStatus();
  const { osId } = useGeneral();
  const { propertyData, getDataCallback } = usePropertyData({
    status: Status.REPORT,
  });
  const { handleRooms, rooms } = useRooms();
  const { getErrorMessage } = useErrorMessage();
  const { handleSnackbar } = useSnackbar();

  const getSelectedPictures = async (inspectionId: number): Promise<void> => {
    try {
      const response = await inspectionAPI.getSelectedInspectionPictures(
        inspectionId
      );

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setSelectedPictures(response.data);
    } catch (error) {
      handleSnackbar(getErrorMessage(error), true);
    }
  };

  const getSample = async (): Promise<void> => {
    let sample = false;
    let isFactors = false;

    if (propertyData) {
      if (propertyData.force_factors) {
        setForceFactors(propertyData.force_factors);
      }
      if (propertyData.force_transformations) {
        setForceTransformations(propertyData.force_transformations);
      }
      if (propertyData.inspection) {
        handleRooms(propertyData.inspection.id);
        getSelectedPictures(propertyData.inspection.id);
      }
      const avmData = propertyData.avm_report as
        | AvmInferenceData
        | AvmFactorsData
        | null;
      if (avmData && avmData.scores) {
        setHasAvmReport(true);
      }
      if (
        propertyData.evaluation_type === EvaluationType.AUTOFACTORS ||
        propertyData.evaluation_type === EvaluationType.SIMPFACTORS
      ) {
        isFactors = true;
      } else if (avmData) {
        const avmInference = avmData as AvmInferenceData;
        setGraphsLength(5 + avmInference.independent_variables_plots.length);
      }
      if (propertyData.latitude && propertyData.longitude) {
        setPinPlace({
          lat: Number(propertyData.latitude),
          lng: Number(propertyData.longitude),
        });
      }
      if (propertyData.avm_status?.failure_reason) {
        setAvmStatus(propertyData.avm_status?.failure_reason);
      }
      if (propertyData.samples) {
        sample = true;
      }
      setHasGraphs(propertyData.has_graphs);
      setUpdatedAdditionalFields(!updatedAdditionaFields);

      setLoadingPage(false);
    }
    if (sample) {
      setLoadingSample(true);

      try {
        const response = await sampleAPI.getSample(osId, 1, 105);

        if (response.detail.description) {
          throw new Error(response.detail.description);
        }

        if (!response.data) {
          throw new Error('A amostra não pode ser carregada.');
        }

        if (response.data.max_radius) {
          setSampleRadius(response.data.max_radius);
        }

        if (isFactors) {
          const filteredSamples: ElementData[] = response.data.items.filter(
            (data) => data.is_utilized
          );
          setSampleData(filteredSamples);
          return;
        }
        setSampleData(response.data.items);
        setLoadingSample(false);
      } catch (error) {
        handleSnackbar(getErrorMessage(error), true);
        setLoadingSample(false);
      }
    }
  };

  useEffect(() => {
    getSample();
  }, [propertyData]);

  const getCharts = useCallback(
    async (img: HTMLCanvasElement, title: string): Promise<void> => {
      if (hasGraphs) return;

      const subtitles = [
        'Regressão',
        'Intervalo de predição',
        'Intervalo de confiança',
      ];
      const substring1 = ['Gráfico de Aderência -'];
      const substring2 = ['Privativa', 'Garagem', 'Cub', 'IBGE'];
      let newGraphMetadata: SendGraphProps;

      img.toBlob((blob) => {
        if (blob) {
          const imageFile = new File([blob], title, { type: 'image/png' });

          newGraphMetadata = { title };

          const containOneSubtitle = substring1.some((sub1) =>
            title.includes(sub1)
          );

          const containThreeSubtitles = substring2.some((substring) =>
            title.includes(substring)
          );
          if (containOneSubtitle) newGraphMetadata.subtitle = ['Bissetriz'];
          if (containThreeSubtitles) newGraphMetadata.subtitle = subtitles;

          setFiles((prevFiles) => [...prevFiles, imageFile]);
          setMetadata((prevMetadata) => [...prevMetadata, newGraphMetadata]);
        }
      });
    },
    [files]
  );

  const handleReportGraphs = useCallback(async (): Promise<void> => {
    if (hasGraphs) return;

    const formData = new FormData();
    for (let i = 0; i < files.length; i += 1) {
      formData.append('files', files[i]);
      formData.append('metadata', JSON.stringify(metadata[i]));
    }

    try {
      const response = await workOrderAPI.sendGraphs(osId, formData);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error(
          'Gráficos não foram salvos, carregue a página novamente.'
        );
      }

      setHasGraphs(true);
    } catch (error) {
      handleSnackbar(getErrorMessage(error), true);
    }
  }, [files]);

  useEffect(() => {
    if (propertyData && files.length === graphsLength) {
      handleReportGraphs();
    }
  }, [files, graphsLength]);

  const updateGoogleMap = useCallback(
    async (
      osPin: LatLngLiteral,
      samplePins: ElementData[],
      evaluationType: 'factors' | 'inferences'
    ) => {
      const mapSize = evaluationType === 'factors' ? '460x390' : '580x480';
      let zoom = 14;
      if (sampleRadius && sampleRadius < 1.5) zoom = 15;
      if (sampleRadius && sampleRadius < 1.0) zoom = 16;

      const formatMarkers = (sample: ElementData[]): string =>
        sample
          .map(
            (element) =>
              `${element.description.latitude}, ${element.description.longitude} |`
          )
          .join(' ');

      const usedElements = samplePins.filter((element) => element.is_utilized);
      const unusedElements = samplePins.filter(
        (element) => !element.is_utilized
      );
      const usedMarkers = formatMarkers(usedElements);
      const unusedMarkers = formatMarkers(unusedElements);
      const usedElementIcon =
        'https://app-st.protoai.com.br/images/element-icon.png';
      const unusedElementIcon =
        'https://app-st.protoai.com.br/images/unused-icon.png';

      const baseUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${osPin.lat},${osPin.lng}&style=feature:poi|element:labels|visibility:off&zoom=${zoom}&size=${mapSize}&scale=2`;
      const mapType = '&maptype=satellite';
      const workOrderMarker = `&markers=color:0xe67615|size:mid|${osPin.lat},${osPin.lng}&markers=scale:2|icon:${usedElementIcon}|`;
      const unusedMarkersString =
        evaluationType === 'inferences'
          ? `&markers=scale:2|icon:${unusedElementIcon}|${unusedMarkers}`
          : '';
      const apiKey = `&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;

      const mapFile = await fetch(
        `${baseUrl}${workOrderMarker}${usedMarkers}${unusedMarkersString}${apiKey}`
      )
        .then((r) => r.blob())
        .then(
          (bFile) => new File([bFile], 'map-elements', { type: 'image/png' })
        );

      const mapSatelliteFile = await fetch(
        `${baseUrl}${mapType}${workOrderMarker}${usedMarkers}${unusedMarkersString}${apiKey}`
      )
        .then((r) => r.blob())
        .then(
          (bFile) =>
            new File([bFile], 'satellite-map-elements', { type: 'image/png' })
        );

      const mapImageData = new FormData();
      mapImageData.append('google_maps', mapFile);
      mapImageData.append('satellite_view', mapSatelliteFile);

      try {
        const response = await workOrderAPI.addGoogleMapsImagesWithPins(
          osId,
          mapImageData
        );

        if (response.detail.description) {
          throw new Error(response.detail.description);
        }

        if (response.detail.status_code !== StatusCode.OK) {
          throw new Error('Algo deu errado, tente novamente.');
        }
      } catch (error) {
        handleSnackbar(getErrorMessage(error), true);
      }
    },
    []
  );

  const handleChangeStatus = async (): Promise<void> => {
    setLoadingApprove(true);
    if (propertyData?.avm_report && propertyData.avm_status !== null) {
      const { dropdown } = propertyData.avm_report.descriptions;
      if (
        !dropdown ||
        dropdown.performance.chosen === '' ||
        dropdown.number_of_offers.chosen === '' ||
        dropdown.liquidity.chosen === ''
      ) {
        handleSnackbar('Os dados de seleção são obrigatórios', true);
        setLoadingApprove(false);
        return;
      }
    }

    if (
      propertyData &&
      propertyData.avm_status !== null &&
      pinPlace &&
      sampleData
    ) {
      const isFactorsEvaluation =
        propertyData.evaluation_type === EvaluationType.AUTOFACTORS ||
        propertyData.evaluation_type === EvaluationType.SIMPFACTORS;

      const evaluationType = isFactorsEvaluation ? 'factors' : 'inferences';

      updateGoogleMap(pinPlace, sampleData, evaluationType).then(async () => {
        let hasPdf = false;
        try {
          const response = await workOrderAPI.generateReportPDF(osId);
          if (response.detail.description) {
            throw new Error(response.detail.description);
          }
          if (response.detail.status_code !== StatusCode.OK) {
            throw new Error('Algo deu errado, tente novamente.');
          }
          hasPdf = true;
        } catch (error) {
          handleSnackbar(getErrorMessage(error), true);
          setLoadingApprove(false);
        }
        if (hasPdf) {
          handleStatus(osId, propertyData?.reference_number);
        }
      });
    } else {
      handleStatus(osId, propertyData?.reference_number);
    }
  };

  const removeSelectedPicture = async (id: number): Promise<void> => {
    if (!propertyData?.inspection) return;

    const updatePictures = selectedPictures.filter((photo) => photo.id !== id);

    const formatted = updatePictures.map((picture) => {
      return { id: picture.id, selected_index: picture.selected_index };
    });

    const selectedPicturesData = {
      inspection_id: propertyData.inspection.id,
      selected_pictures: formatted,
    };

    try {
      const response = await pictureAPI.selectInspectionPictures(
        selectedPicturesData
      );

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.detail.status) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setSelectedPictures(updatePictures);
      handleSnackbar('Imagem removida com sucesso!', false);
    } catch (error) {
      handleSnackbar(getErrorMessage(error), true);
    }
  };

  const updateAdditionalFields = (): void =>
    setUpdatedAdditionalFields(!updatedAdditionaFields);

  useEffect(() => {
    if (propertyData?.avm_report) {
      const { dropdown } = propertyData.avm_report.descriptions;
      if (
        dropdown.performance.chosen !== '' ||
        dropdown.number_of_offers.chosen !== '' ||
        dropdown.liquidity.chosen !== ''
      ) {
        setCompletedSections((prev) => ({ ...prev, additionalFields: true }));
      } else {
        setCompletedSections((prev) => ({ ...prev, additionalFields: false }));
      }
    }
  }, [updatedAdditionaFields]);

  useEffect(() => {
    if (rooms.length > 0 && selectedPictures.length > 0) {
      setCompletedSections((prev) => ({ ...prev, inspectionImages: true }));
    } else if (rooms.length === 0) {
      setCompletedSections((prev) => ({ ...prev, inspectionImages: true }));
    } else {
      setCompletedSections((prev) => ({ ...prev, inspectionImages: false }));
    }
  }, [selectedPictures]);

  return {
    propertyData,
    sampleData,
    hasAvmReport,
    rooms,
    selectedPictures,
    getCharts,
    handleChangeStatus,
    forceFactors,
    forceTransformations,
    loadingApprove,
    loadingPage,
    loadingSample,
    removeSelectedPicture,
    avmStatus,
    completedSections,
    updateAdditionalFields,
    getDataCallback,
  };
};

export default useReport;
